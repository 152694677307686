<template>
    <div class="risks-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="risks-list row g-2 mb-4">
                <div class="col-md-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search risks"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <div class="col-md-auto">
                    <date-range-picker
                        ref="picker"
                        v-model="range"
                        opens="right"
                        time-picker
                        time-picker-24-hours
                        show-dropdowns
                        control-container-class="risks-list__date-range-picker"
                        :linked-calendars="false"
                        @update="changeDate"
                    >
                        <template #input>
                            <em class="fa fa-fw fa-calendar ml-n1"></em>
                            {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                            {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                            <strong class="caret"></strong>
                        </template>
                    </date-range-picker>
                </div>
                <div class="col-md-3">     
                    <ui-select-multiple
                        id="riskLevelFilter"
                        name="riskLevelFilter"
                        label="Level"
                        class="w-100"
                        option-label="label"
                        option-value="value"
                        :options="level"
                        v-model:value="filters.level"
                    />   
                </div>
            </div>
            <ui-table class="mb-4" :items="risks" :fields="fields" :busy="busy">
                <template #cell(origin)="data">
                    <div class="d-flex align-items-center">
                        <div class="risks-list__container">
                            <div v-if="data.item.shipping_address?.country?.code">
                                <img class="risks-list__container--flag" :src="`https://envia-clients-new.herokuapp.com/flags/${data.item.shipping_address.country.code.toLowerCase()}.svg`" :alt="data.item.shipping_address.country.code" />
                            </div>
                        </div>
                        <div>
                            <template v-if="data.item.customer || data.item.shipping_address">
                                <span v-if="data.item.customer && data.item.customer.email">
                                    <strong>Email: </strong>
                                    <ui-link underline weight="regular" :link="'mailto:' + data.item.customer.email">
                                        <span>{{ data.item.customer.email }}</span>
                                    </ui-link>
                                </span>
                                <span v-else-if="data.item.customer && data.item.customer.phone">
                                    <strong>Phone: </strong>
                                    <ui-link underline weight="regular" :link="`tel:+${data.item.customer.phone}`">
                                        <span>{{ data.item.customer.phone }}</span>
                                    </ui-link>
                                </span>
                                <div v-if="data.item.shipping_address">
                                    <div v-if="data.item.shipping_address.postal_code">
                                        <strong>Postal code:</strong>
                                        {{ data.item.shipping_address.postal_code }}
                                    </div>
                                    <div v-if="data.item.shipping_address.street">                                
                                        <strong>Street:</strong>
                                        {{ data.item.shipping_address.street }}
                                    </div>
                                </div>
                            </template>
                            <span v-else class="badge bg-light text-dark">No information</span>
                        </div>
                    </div>
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>   
                <template #cell(updated_at)="data">
                    {{ $dayjs(data.item.updated_at).format('LL') }} <br />
                    {{ $dayjs(data.item.updated_at).format('LTS') }}
                </template> 
                <template #cell(level)="data">
                    <ui-risk-level
                        class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                        :risk_level="data.item.level"
                        :risk_score="data.item.score"
                    />
                </template>
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link';
import UiPagination from '@/components/ui/Pagination';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiRiskLevel from '@/components/ui/BadgeRiskLevel';
import UiTable from '@/components/ui/Table';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton';

import table_mixin from '@/mixins/tables.mixin';
import { filters as data_filters } from '@/data';

export default {
    components: {
        DateRangePicker,
        UiInput,
        UiLink,
        UiPagination,
        UiSelectMultiple,
        UiRiskLevel,
        UiTable,
        UiTableSkeleton
    },
    mixins: [table_mixin],
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };

        return {
            busy: false,
            risks: null,
            creating: false,
            fields: [
                { key: 'origin', label: 'Origin of the risk' },
                { key: 'level', label: 'Level' },
                { key: 'created_at', label: 'Created at' },
                { key: 'updated_at', label: 'Updated at' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                range: { ...range },
            },
            range: { ...range },
            inititialLoading: true,
            loading: false,
            total_pages: null,
        };
    },
    computed: {
        level() {
            return data_filters.risks.level;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getRisks);
            },
        }
    },
    methods: {
        changeDate(data) {
            this.filters.range = data;
        },
        async getRisks() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    level: this.filters.level,
                    'created_at[from]': this.$dayjs(this.filters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(this.filters.range.endDate).toISOString(),
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/risks', { params });

                this.total_pages = data.pages;
                this.risks = data.docs;          
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
    },
};
</script>

<style lang="scss">
.risks-list {
    &__container {
        margin-right: 8px;
        width: 24px;

        &--flag {
            border-radius: 6px;
            box-shadow: 0 2px 4px rgba(#000000, 0.15);
            height: 24px;
        }
    }

    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>
